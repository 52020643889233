/* Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) 

/Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) 

 Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) 

 Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px)  */
/* * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  } */
  
  h2{
      color: #1c58b5;
      font-weight: 600;
      margin-bottom: 3vh;
      text-align: center !important;
  }
  .states-container{
    text-align: center;
    width: 100%;
}

.raleway{
    font-family: "Raleway", sans-serif !important;

}
 .spacer{
  height: 10vh;
  width: 100%;
 }
.subtitle{
    width: 65%;
    text-align: center;
 }

/* title start */
.jumbotron {
    width: 100% !important;
  }

.states-hero {
    height: 200px;
    background-image: linear-gradient(
        to right bottom,
        rgba(9, 17, 30, 0.849),
        rgba(9, 17, 30, 0.192)
      ),
      url("../images/general/breakout.jpg");
    background-size: cover;
    position: relative;
  }
  
  .states-hero-text {
    font-weight: bold;
    font-size: 3rem;
    line-height: 4rem;
    color: #ffffff;
    text-align: center;
    margin: 0 auto;
  }
  

  .states-subtitle{
      color: white;
      font-size: 23px;
      width: 75%;
      text-align: center;
      margin: 0 auto;
  }

  .states-title-text{
      margin: 0;
  }
/* title end */

.states-quote-section {
    font-family: "Raleway", sans-serif !important;
    background-color: #d7d7d7;
    height: 20vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    }

.states-links{
    color: #1c58b5;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 4vh;
    font-weight: 600;
    }

.states-links:hover{
    color: #ed185b;
    }

    
/* buttons */

.map-btn-states-pdfs {
    /* display: inline-block; */
    margin:0 auto;
    display: block;
    position: relative;
    line-height: 55px;
    float: none;

    width: 52vh;
    height: 55px;
    margin-bottom: 7vh;
    background: #ed185b;
    border: none;
    border-radius: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all ease 0.3s; 
  }
  
  .map-btn-states:hover {
    text-decoration: none;
    color: #ffffff !important;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
  }
  .map-btn-states:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) !important;
  }