/* Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) 

/Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) 

 Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) 

 Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px)  */

.image-break-container{
  height: 70vh;
  width: 100% !important;
  margin-bottom: 0 !important;

}
.image-break {
  height: 100%;
  background-image: 
  linear-gradient(
      to right bottom,
      rgba(37, 37, 37, 0.149),
      rgba(15, 15, 15, 0.192)
    ),
    url("https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Fsymp4.jpg?alt=media&token=960cdb8a-a888-41bd-92d0-44d9234551be");
  background-size: 100%;
  background-position: center;
  background-position-y: 17%;
  position: relative;
  background-repeat: no-repeat;
}

.workshop-2017-hero {
  height: auto;
  background-image: linear-gradient(
      to right bottom,
      rgba(9, 17, 30, 0.795),
      rgba(7, 13, 22, 0.219)
    ),
    url("https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Fteach-cs.jpg?alt=media&token=89dc916c-d1c2-45b9-b1d0-9e5956abb222");
  background-size: cover;
  background-position: center;
  position: relative;
  /* background-position-y: -5vh; */
}
.workshop-2017-hero-container {
  margin-top: 20vh;
  margin-bottom: 20vh;
}
.workshop-2017-hero-container p {
  margin-bottom: 1%;
}
.workshop-2017-hero-text {
  font-weight: bold;
  font-size: 3rem;
  line-height: 4rem;
  color: #ffffff;
  text-align: left;
  width: 80%;
}

.workshop-2017-hero-subText {
  text-align: left;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 28px;

  color: #ffffff;
}

.hr-2017-workshop {
  border: 0;
  clear: both;
  display: block;
  width: 40%;
  background-color: white;
  height: 2px;
  margin-top: 5%;
  margin-bottom: 3%;
}
@media only screen and (max-width: 575.98px) {
  .hr-2017-workshop {
    width: 50%;
  }
  .workshop-2017-hero-container {
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
  .workshop-2017-hero-text {
    font-size: 2rem;
    line-height: 3.5rem;
    width: 80%;
  }
  .workshop-2017-hero-subText {
    font-size: 1.3rem;
    line-height: 30px;
    width: 90%;
  }
}

/* video */
.video-container         {
  width:80%;
  height:100%;
  margin:0 auto;
  background:#CCC
  }
.video-iframe-container {
  position:relative;
  }
.video-iframe-container .ratio {
  display:block;
  width:100%;
  height:auto;
  }
.video-iframe-container iframe {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  }



/* O V E R V-I E W S E C T  I O N  */
.hr-overview-2017-workshop {
  border: 0;
  clear: both;
  width: 10%;
  background-color: #767676;
  height: 2px;
  margin-top: 5%;
  margin-bottom: 3%;
}

.workshop-2017-overview-box {
  padding-top: 5%;
  padding-bottom: 5%;
}

.workshop-2017-intro-heading {
  font-family: Raleway;
  font-style: bold;
  font-weight: bold;
  font-size: 2.4rem;
  text-align: center;
  color: #484848;
}
.workshop-intro-content {
  margin-top: 5%;
  font-size: 1.2rem;
  line-height: 1.9rem;

  text-align: center;

  color: #484848;
}
.workshop-intro-content p {
  margin-bottom: 5%;
}

.workshop-2017-overview-video-preview {
  width: 100%;
  background-image: url("../images/2018-workshop-video-reel.png");
  background-size: cover;
  background-position: top;
}

@media only screen and (max-width: 575.98px) {
  .workshop-intro-content {
    line-height: 35px;

    text-align: left;
  }
  .workshop-2017-intro-heading {
    font-size: 2.2rem;
    text-align: left;
  }

  .hr-overview-2017-workshop {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 30%;
    margin-bottom: 7%;
  }
}
/* G O A L S  S E C T I O N  */
.workshop-2017-goals-section {
  background-color: #f7f7f7;
  text-align: center;

}

.workshop-2017-goals-info {
  margin-top: 5rem;
}
.workshop-2017-goals-info h2 {
  font-size: 2.6rem;
  line-height: 2.22rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 3%;
}
.workshop-2017-goals-info p {
  width: 70%;
  margin-top: 1rem;
  font-size: 1.2rem;
  line-height: 30px;
  color: #484848;
  margin: 0 auto;
}
.workshop-2017-goals-items {
  margin-top: 5rem;
}

/* add white space between items and overall container  */
.workshop-2017-goals-items div {
  margin-bottom: 3rem;
}
/* individually move down the third item on large screen by a bit to be even with others  */
.workshop-2017-goals-items > div:nth-child(3) {
  margin-top: 0.8rem;
}
.workshop-2017-goals-item-heading {
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  color: #1c58b5;
  font-weight: bold;
}
.workshop-2017-goals-item-text {
  font-size: 1.125rem;
  line-height: 1.9rem;
  color: #484848;
}
.workshop-2017-goals-item-image {
  width: 6rem;
  margin-bottom: 7vh;
}
@media only screen and (max-width: 991.98px) {
  .workshop-2017-goals-info p {
    width: 100%;
  }
  .workshop-2017-goals-items div {
    margin-bottom: 5rem;
  }

  .workshop-2017-goals-item-heading {
    margin-top: 2rem;
    margin-bottom: 1rem;

    font-size: 1.2rem;
  }
  .workshop-2017-goals-items > div:nth-child(3) {
    margin-bottom: 3rem;
    margin-top: 0.8rem;
  }
  .workshop-2017-goals-info h2 {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 575.98px) {
  .workshop-2017-goals-items div {
    margin-bottom: 1rem;
  }
  .workshop-2017-goals-items > div:nth-child(3) {
    margin-top: 0;
  }
  .workshop-2017-goals-items {
    margin-top: 2rem;
  }
}

/* S T R A T E G I E S  S E C I T O N  */
.workshop-2017-strategies-section {
  padding-top: 5%;
}
.hr-strategies-2017-workshop {
  border: 0;
  clear: both;
  width: 10%;
  background-color: #767676;
  height: 2px;
  margin-top: 5%;
  margin-bottom: 3%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.workshop-2017-strategies-section h2 {
  font-size: 2.6rem;
  line-height: 2.5rem;
  font-family: Raleway;
  font-weight: bold;
  text-align: center;
  color: #484848;
  margin-bottom: 5.5rem;
}
.workshop-2017-strategies-section p {
  line-height: 1.9rem;
  font-weight: normal;
  color: #484848;
  width: 90%;
  margin-top: 1rem;
  font-size: 1.125rem;
  margin-bottom: 10%;
  width: 80%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.workshop-2017-strategies-section h3 {
  font-size: 1.5rem;
  color: #1c58b5;
  font-weight: bold;
  width: 80%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.workshop-2017-strategies-section img {
  max-width: 100%;
  text-align: center;
  width: auto;
  display: block;
  margin-top: 5rem;
}
@media only screen and (max-width: 991.98px) {
  .workshop-2017-strategies-section p {
    line-height: 1.9rem;
    width: 100%;
  }
  .workshop-2017-strategies-section h3 {
    width: 100%;
  }
}
@media only screen and (max-width: 575.98px) {
  .workshop-2017-strategies-section h2 {
    text-align: left;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }
  .workshop-2017-strategies-section img {
    margin-top: 2rem;
  }
  .hr-strategies-2017-workshop {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 30%;
    margin-bottom: 7%;
  }
}
/* FRAMEWORK SECTION  */
.workshop-2017-framework-section {
  padding-top: 5%;
}
.hr-framework-2017-workshop {
  border: 0;
  clear: both;
  width: 10%;
  background-color: #767676;
  height: 2px;
  margin-top: 5%;
  margin-bottom: 3%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.workshop-2017-framework-section h2 {
  font-size: 2.6rem;
  line-height: 2.5rem;
  font-family: Raleway;
  font-weight: bold;
  text-align: center;
  color: #484848;
  margin-bottom: 4rem;
}
.workshop-2017-framework-section p {
  font-size: 1.3rem;
  line-height: 1.9rem;
  text-align: center;
  color: #484848;
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.workshop-2017-framework-section h3 {
  font-size: 1.5rem;
  color: #1c58b5;
  font-weight: bold;
}
.workshop-2017-framework-section img {
  max-width: 100%;
  text-align: center;
  width: auto;
  display: block;
}
@media only screen and (max-width: 991.98px) {
  .workshop-2017-framework-section p {
    line-height: 1.9rem;
    width: 100%;
  }
}
@media only screen and (max-width: 575.98px) {
  .workshop-2017-framework-section h2 {
    text-align: left;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }
  .hr-framework-2017-workshop {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 30%;
    margin-bottom: 7%;
  }
  .workshop-2017-framework-section p {
    line-height: 35px;

    text-align: left;
  }
  .workshop-2017-framework-section {
    padding-top: 15%;
  }
}

.workshop-2017-framework-section-text-container {
  /* margin-top: 5%; */
  margin-top: 2rem;
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.workshop-2017-framework-section-content {
  height: 100%;
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.workshop-2017-framework-section-text-container h3 {
  font-size: 1.5rem;
  color: #1c58b5;
  font-weight: bold;
}

.workshop-2017-framework-section-text-container p {
  padding-top: 1rem;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.75);
}

.workshop-2017-framework-section-content img {
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
  /* background-size: cover;
  background-position: top; */
  display: block;
}

@media only screen and (max-width: 991.98px) {
  .workshop-2017-framework-section-text-container {
    margin-top: 0;
  }
}
@media only screen and (max-width: 575.98px) {
  .workshop-2017-framework-section-text-container {
    margin-top: 0;
  }

  .workshop-2017-framework-section-content img {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .workshop-2017-framework-section-text-container {
    margin-top: 0;
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .workshop-2017-framework-section-content {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}