/* Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) 

/Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) 

 Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) 

 Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px)  */
.container-width{
  width: 90%;
  margin: 0 auto;
}
.report-2018-hero {
  height: auto;
  background: #1c58b5;
  background-size: cover;
  background-position: top;
  position: relative;
}
.report-2018-hero-content-container-left {
  margin-top: 20vh;
  margin-bottom: 20vh;
}

.report-2018-hero-content-container-right {
  margin-top: 10vh;
}

@media only screen and (max-width: 1200px) {
  .report-2018-hero-content-container-left {
    margin-top: 10vh;
  }
  .report-2018-hero-content-container-right {
    margin-top: 6vh;
  }
}
@media only screen and (max-width: 992.98px) {
  .report-2018-hero-content-container-left {
    margin-top: 10vh;
  }
  .report-2018-hero-content-container-right {
    margin-top: 10vh;
  }
}
.report-2018-hero-header {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 4rem;
  color: #ffffff;
}
.report-2018-hero-subHeader {
  font-family: Raleway;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2rem;

  color: #ffffff;
}
.report-2018-hero-miniHeader {
  font-family: Raleway;
  font-weight: 500;
  font-size: 1.2rem;

  color: rgba(255, 255, 255, 0.75);
}

.report-2018-hero-image {
  width: 100%;
  margin: 5px;
  background-size: cover;
  background-position: top;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.report-2018-hero-image2 {
  width: 100%;
  margin: 5px;
  background-size: cover;
  background-position: top;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.report-2018-hero-image3 {
  width: 100%;
  margin: 5px;
  background-size: cover;
  background-position: top;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

/* I N T R O   S E C T I O N */
.report-2018-intro-content {
  position: relative;
  margin-top: 10%;
}
/* mobile */
@media only screen and (max-width: 575.98px) {
  .report-2018-intro-content {
    margin-top: 8%;
  }
}
.report-2018-intro-heading {
  font-family: Raleway;
  font-weight: bold;
  font-size: 2.6rem;

  color: #484848;
  margin-bottom: 3%;
}

.report-2018-intro-text {
  font-size: 1.125rem;
  line-height: 2rem;

  color: #484848;
}
.report-2018-intro-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

@media only screen and (max-width: 992.98px) {
  .report-2018-intro-image {
    width: 100%;
    top: 0%;
    left: 0%;
    transform: none;
    position: relative;
    margin-bottom: 5%;
  }
}

.report-2018-graphic-section {
  background: rgba(10, 54, 121, 0.25);
  margin-top: 5%;
}

/* G R A P H I C  S E C T I O N  */
.report-2018-graphic-section-image {
  width: 100%;
}
.report-2018-section-title {
  font-family: Raleway;
  font-weight: 600;
  font-size: 1.9rem;
  color: #0a3679;
  padding-top: 10%;
  margin-bottom: 10%;
}
.report-2018-accordion-card {
  margin-bottom: 10px !important;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.report-2018-accordion-head {
  background: #36b8f8;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 1.1rem;
  opacity: 1;
  transition: all ease 0.3s;
}

.report-2018-accordion-head:hover {
  background: #1c58b5;
  cursor: pointer;
}

@media only screen and (max-width: 992.98px) {
  .report-2018-accordion-head:hover {
    background: #36b8f8;
  }
}

.report-2018-card-body {
  color: #484848;
}
/* O R G A N I Z E R S   S E C T  I O N  */
.report-2018-organizers-heading {
  font-family: Raleway;
  font-style: bold;
  font-weight: bold;
  font-size: 2.4rem;
  margin-top: 10%;
  color: #484848;
}
.report-2018-organizers-container {
  margin-top: 10vh;
  margin-bottom: 10vh;
}
.report-2018-card-title {
  margin-top: 1rem;
  font-weight: bold;
}
.report-2018-card-subHeader {
  font-weight: 400;
  line-height: 1.5rem;
}
.report-2018-modal-subHeader {
  font-weight: 400;
  margin-top: 1;
  font-size: 1rem;
  margin-bottom: 0%;
}
.report-2018-card-text{
 min-height: 4em;
}

.close {
  color: white;
  font-size: 2rem;
}
.close:hover {
  color: rgb(214, 214, 214);
  font-size: 2rem;
}
.close:active {
  color: rgb(238, 238, 238);
  font-size: 2rem;
}
.report-2018-modal-button {
  font-family: Raleway;
  font-weight: bold;
  line-height: 1rem;
  color: #1c58b5 !important;
  cursor: pointer;
  margin-right: auto;
}
.report-2018-modal-button:hover {
  color: #36b8f8 !important;
}

.close-modal {
  display: inline-block;
  padding: 0.3rem 2.5rem 0.3rem 2.5rem;
  background: #ed185b;
  border: none;
  border-radius: 2px;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  position: relative;
  transition: all ease 0.2s;
}

.close-modal:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
}
.close-modal:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) !important;
}

.report-2018-organizers-card {
  border: none;
  margin-top: 10%;
}
@media only screen and (max-width: 575.98px) {
  .report-2018-organizers-card {
    border: none;
    width: 80%;
  }
}
.report-2018-model-header {
  background: #1c58b5;
  color: white;
}
/* S U P P O R T E R S  S E C T  I O N  */
.report-2018-supporters-container {
  background: #f6f6f6;
}
.report-2018-supporters img {
  max-width: 50%;
}
.report-2018-supporters div {
  margin-bottom: 5%;
  text-align: center;
}

.report-2018-supporters {
  padding-bottom: 5%;
  text-align: center;
}

@media only screen and (max-width: 992.98px) {
  .report-2018-supporters img {
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .report-2018-supporters div {
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .report-2018-supporters {
    padding-bottom: 10%;
  }
}
@media only screen and (max-width: 768.98px) {
  .report-2018-supporters img {
    margin-top: 2%;
    margin-bottom: 2%;
    max-width: 40%;
  }
  .report-2018-supporters div {
    margin-bottom: 15%;
    margin-top: 5%;
  }
}

@media only screen and (max-width: 576px) {
  .report-2018-supporters img {
    margin-top: 2%;
    margin-bottom: 2%;
    max-width: 50%;
  }
}

/* F O C U S  A R E A  C A R D S */
.focus-area-card-image {
  margin-top: 3%;
  width: 40%;
}
.card-odd {
  border-radius: 100%;
  border: 3px solid #cb832f;
}
.card-even {
  border-radius: 100%;
  border: 3px solid #74c5ea;
}
.bottom-border-odd {
  border-radius: 0px 0px 4px 4px;
  background: #cb832f;
  height: 10px;
}
.bottom-border-even {
  border-radius: 0px 0px 4px 4px;
  background: #74c5ea;
  height: 10px;
}
.report-2018-card-title {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
}

.focus-area-card-button {
  display: inline-block;
  width: 18vh;
  background: #1c58b5;
  text-align: center;
  color: #ffffff;
  position: relative;
  margin-right: 5%;
  margin-left: 5%;
}
@media only screen and (max-width: 1199px) {
  .focus-area-card-button {
    width: 100%; 
    display:block;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.report-2018-focus-area-card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  width: 90%;
  margin-bottom: 10% !important;
}

@media only screen and (max-width: 992.98px) {
  .report-2018-focus-area-card {
    width: 100%;
  }
}
