.center{
    margin: 0 auto;
    text-align: center;
}

 .spacer{
  height: 20px;
  width: 100%;
 }
.subtitle{
    width: 65%;
    text-align: center;
 }

.timeline-bg{
    background-color: #F7F7F7;
}

/* title start */
.jumbotron {
    width: 100% !important;
  }
.about-hero {
    height: 800px;
    background-image: linear-gradient(
        to right bottom,
        rgba(9, 17, 30, 0.849),
        rgba(9, 17, 30, 0.192)
      ),
      url("../images/About/grads-back.jpg");
    background-size: cover;
    /* background-position: -100px; */
    position: relative;
  }
  
  .about-hero-text {
    font-weight: bold;
    font-size: 3rem;
    line-height: 4rem;
    color: #ffffff;
    text-align: center;
    margin: 0 auto;
  }
  

  .about-subtitle{
      color: white;
      font-size: 23px;
      width: 75%;
      text-align: center;
      margin: 0 auto;
  }

  .about-title-text{
      margin: 0;
  }
/* title end */

/* quote yellow start */

.about-quote-section {
    background: rgba(255, 191, 25, 0.25);
    height: 65vh;
    margin: auto;
    text-align: center;
    display: table;

    }
  @media only screen and (max-width: 575.98px) {
    .about-quote-section {
      margin-bottom: 10%;
    }
  }
  .about-quote-vert{
    display: table-cell;
    vertical-align: middle;
  }
  .about-quote-title{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 6vh;
    
}

  .about-quote {
    font-weight: 500;
    font-size: 3rem;
    width: 71%;
    margin: 0 auto;
    color: rgba(9, 9, 9, 0.85);
    line-height: 55px;
    padding-bottom: 6vh;

  }

  /* mobile */
  @media only screen and (max-width: 575.98px) {
    .about-quote {
      line-height: 50px;
    }
  }
 
/* quote yellow end */

/* our story start */
    .about-story-box{
        padding-top: 10%;
        padding-bottom: 7%;
        width: 80%;
        margin:0 auto;
    }
    /* desktop */
    @media only screen and (max-width: 1199.98px) {
    .about-story-box {
        padding-top: 10%;
        padding-left: 0%;
        padding-right: 0%;
        }
    }
    /* mobile */
    @media only screen and (max-width: 575.98px) {
        .about-story-box {
        padding-top: 0%;
    }
}

     .about-story-heading {
        font-style: bold;
        font-weight: bold;
        font-size: 2.4rem;
      
        color: #484848;
        /* color: #ed185b; */
      }
      .about-story-content {
        margin-top: 3%;
        font-size: 1.125rem;
      
        color: #484848;
      }
      /* mobile */
      @media only screen and (max-width: 575.98px) {
        .about-story-content {
          margin-top: 8%;
        }
      }

      .story-img{
        width: 80%;
         text-align: center;
        top: 3vh;
      }

/* our story end */


/* goals start */
/* G O A L S  S E C T I O N  */
.about-goals-section {
    background-color: #f7f7f7;
    text-align: center;

   }
  
  .about-goals-info {
    padding-top: 5rem;
    width: 60%;
    margin: 0 auto;
    /* height: 55vh; */
  }
  .about-goals-bold {
     line-height: 2.22rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 3%;
  }
  .about-goals-bold {
    line-height: 2.22rem;
    color: #333;
   margin-bottom: 3%;

 }
  .about-goals-items {
    margin-top: 5rem;
  }
  
  /* add white space between items and overall container  */
  .about-goals-items div {
    margin-bottom: 3rem;
  }
  /* individually move down the third item on large screen by a bit to be even with others  */
  .about-goals-items > div:nth-child(3) {
    margin-top: 0.8rem;
  }
  .about-goals-item-heading {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    color: #1c58b5;
    font-weight: bold;
  }
  .about-goals-item-text {
    font-size: 1.125rem;
    line-height: 1.9rem;
    color: #484848;
  }
  .about-goals-item-image {
    width: 10rem;
  }
  @media only screen and (max-width: 991.98px) {
    .about-goals-info p {
      width: 100%;
    }
    .about-goals-items div {
      margin-bottom: 5rem;
    }
  
    .about-goals-item-heading {
      margin-top: 2rem;
      margin-bottom: 1rem;
  
      font-size: 1.2rem;
    }
    .about-goals-items > div:nth-child(3) {
      margin-bottom: 3rem;
      margin-top: 0.8rem;
    }
    .about-goals-info h2 {
      font-size: 2rem;
    }
  }
  @media only screen and (max-width: 575.98px) {
    .about-goals-items div {
      margin-bottom: 1rem;
    }
    .about-goals-items > div:nth-child(3) {
      margin-top: 0;
    }
    .about-goals-items {
      margin-top: 2rem;
    }
  }


/* goals end */



/* timeline title start */
.about-highlight{
    color: #ed185b;
}

.about-timeline-box {
    padding-top: 10%;
    padding-bottom: 5%;
  }
  
  .about-timeline-text {
    width: 80%;
    text-align: left;
    margin-left: 10%;
    }

  .about-timeline-heading {
    font-family: Raleway;
    font-style: bold;
    font-weight: bold;
    font-size: 3rem;
     color: #484848;
     margin-left: 10%;
  }
 

/* timeline title end */


/* timeline start */

/* timeline end */