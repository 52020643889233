/* Remove input focus */
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.contact-row-container {
  position: relative;
  margin-top: 10%;
}
.contact-container {
  margin-bottom: 5rem;
}
.contact-header {
  font-family: Raleway;
  font-weight: bold;
  font-size: 2.6rem;
  color: #3061ac;
  margin-bottom: 5%;
}
.contact-email {
  font-family: Raleway;
  font-weight: bold;
  color: #3061ac;

}

.contact-message-info {
  font-family: Raleway;
}
.form-control {
  background: #fafafa !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 2px !important;
}
textarea.form-control {
  min-height: 100px;
}
label {
  margin-bottom: 0 !important;
}
