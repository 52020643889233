/* General */
footer {
	padding: 20px;
	position: absolute;
    width: 100%;
    bottom: 0;
    font-weight: normal;
    /* Height needs to match padding-bottom in app.component.css 
    in order for the footer to stay at the bottom of the page */
    height: 2rem;
}

/* CSforALL Logo in Footer */
.footer-logo {
    width: 180px;
    height: auto;
}

.footer-logo img {
	width: 200px;
}

/* Footer Nav */
.footer-logo, .footer-nav {
	display: inline;
}

.footer-nav {
    text-align: center;
}

a {
    color: #1c58b5;
}
a:hover{
    color: #30bbf8 !important;
}

.footer-nav {
	color: #777;
	font-size: 18px;
    font-weight: normal;
    text-decoration: none;
}

.footer-nav {
    color: #30bbf8;
    text-decoration: none;
}

.footer-nav div {
    float: left;
    padding: 20px;
}

@media (max-width: 849px) {
    .footer-nav div {
        float: none;
        padding: 5px; 
    }
}

/* Socials */
.footer-icon {
	font-size: 22px;
	color: #777;
	padding: 0 15px;
}

.footer-icon:hover {
    color: #383838;
}

/* Copyright and Newsletter */
.copyright {
    clear: left;
    padding-left: 40px;
    padding-bottom: 2vh;
}

.copyright-text {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: #9e9e9e;
    font-weight: lighter;
    font-stretch: expanded;
}

.copyright-text a {
    text-decoration: none;
}

.copyright-text a:hover {
    color: #383838;
}

/* Circuit image from the CSforALL website */
.circuit-small {
    top: -2.4vh; 
    transform: rotate(180deg);
    position: relative;

}



@media (max-width: 849px) {
    .circuit-small {
        display: none;
    }
}


.pink{
    color: #30bbf8!important;
}

.pink:hover{
    color: #1c58b5 !important;
}

.footer-info{
    padding-left: 4vh;
    font-size: 11px;
    /* height: 10vh; */
    line-height: 13px;
}

.logo{
    width: 15vh;
    margin-left: 1vh;
    margin-right: 1vh;
    height: auto;
}
.nsf{
    width:10vh;
    margin-left: 1vh;
    margin-right: 1vh;
    height: auto; 
}
.uofi{
    width:7vh;
    margin-left: 1vh;
    margin-right: 1vh;
    height: auto;   
}

.logo-block{
    position: relative;
    bottom: 3vh;
}
.footer-bottom{
    position: relative;
    top: -6vh;
}

.footer-top{
    position: relative;
    top: -5vh;
    left: -5.5vh;
}

