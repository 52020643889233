/* Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) 

/Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) 

 Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) 

 Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px)  */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  line-height: 2.5rem;
  font-size: 20px;
  font-style: normal;
}

.home-hero {
  height: 100%;
  background-image: linear-gradient(
      to right bottom,
      rgba(9, 17, 30, 0.749),
      rgba(9, 17, 30, 0.192)
    ),
    url("https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Fsymp1.jpg?alt=media&token=b10594f5-1e04-4513-812c-59b3d4c7c001");
  background-size: 100%;
  background-position: center;
  background-position-y: 20%;
  /* background-position-x: center; */
  position: relative;
  background-repeat: no-repeat;
}

.home-hero-text {
  text-align: center;
  display: block;
  font-weight: bold;
  font-size: 3rem;
  line-height: 4rem;
  color: #ffffff;
  margin-top: 10rem;
  margin-bottom: 10rem;
  /* max-width: 80%; */
}

.home-vision-box {
  background-color: #f7f7f7;
  padding-top: 9%;
  padding-bottom: 9%;
  padding-left: 10%;
  padding-right: 10%;
}
 @media only screen and (max-width: 1199.98px) {
  .home-hero-text {
    font-size: 2.5rem;
  }
  .home-vision-box {
     padding-bottom: 12%;
    padding-left: 0%;
    padding-right: 0%;
  }
}
  
.home-intro-heading {
  font-style: bold;
  font-weight: bold;
  font-size: 2.4rem;

  color: #484848;
  /* color: #ed185b; */
}
.home-intro-content {
  margin-top: 3%;
  font-size: 1.125rem;

  color: #484848;
}
@media only screen and (max-width: 991.98px) {
  .home-hero {
    height: 70vh;
    background-size: 140% !important;
  }
}
@media only screen and (max-width: 991.98px) {
  .home-hero {
    /* height: 70vh; */
    background-size: 150% !important;
  }
}
/* mobile */
@media only screen and (max-width: 575.98px) {
  .home-hero {
    height: 60vh;
    background-size: 170% !important;
  }

  .home-intro-content {
    margin-top: 8%;
  }
}

@media only screen and (max-width: 440px) {
  .home-hero-text{
    font-size: 1.5rem;
  }

  .home-intro-content {
    margin-top: 8%;
  }
}

@media only screen and (max-width: 375px) {
  .home-hero-text{
    font-size: 1.5rem;
    margin-top: 5rem;
    margin-bottom: 4rem;
  }
  .home-intro-content {
    margin-top: -10%;
  }
}

.home-intro-content-highlight-area {
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  /* or 182% */
  color: #1c58b5;
}
.primary-button {
  display: inline-block;
  padding: 0.5rem 2rem 0.5rem 2rem;
  background: #1c58b5;
  border: none;
  border-radius: 0.1rem;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 1.3rem;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  transition: all ease 0.3s;
  margin-left: 5px;
  margin-right: 5px;
}
.primary-button:hover {
  text-decoration: none;
  color: #ffffff;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
}

.CTA-button {
  display: inline-block;
  padding: 0.5rem 3rem 0.5rem 3rem;
  background: #ed185b;
  border: none;
  border-radius: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  color: #ffffff;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  transition: all ease 0.3s;
}

.CTA-button:hover {
  text-decoration: none;
  color: #ffffff;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
}
.CTA-button:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) !important;
}

.home-quote-section {
  background: rgba(255, 191, 25, 0.25);
  height: 45vh;
  position: relative;
}
@media only screen and (max-width: 575.98px) {
  .home-quote-section {
    margin-bottom: 10%;
  }
}

.home-quote {
  width: 98%;
  margin: 0 auto;
  height: 45vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home-quote-title{
  color: rgba(9, 9, 9, 0.85);
  width: 100%;
  font-weight: 600;
  font-size: 2rem;
}
.home-quote-subtitle{
  color: rgba(103, 103, 103, 0.85);
  width: 90%;
  font-weight: 600;
  font-style: italic;
  font-size: 1.25rem;

}
/* mobile */
@media only screen and (max-width: 575.98px) {
  .home-quote {
    line-height: 50px;
     height: 65vh;
  }
  .home-quote-section{
    height: 65vh;
  }
  .home-quote-title{
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 440px) {
  .home-quote-title{
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 375px) {
  .home-quote-title{
    font-size: .8rem;
  }
  .home-quote-subtitle{
    font-size: .7rem;
  }
}
.home-quote-highlighted-area {
  font-weight: bold;
  color: #1c58b5;
  padding-left: 6px;
}
.hero-info-section {
  margin-top: 15vh;
  margin-bottom: 15vh;
}

.hero-info-sec1-left {
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.3rem;

  color: #484848;
}
.jumbotron {
  width: 100% !important;
  margin-bottom: 0 !important;
}
.hero-info-section-image {
  height: 105vh;
  background-image: linear-gradient(
      to right bottom,
      rgba(9, 17, 30, 0.74),
      rgba(9, 17, 30, 0.08)
    ),
    url("https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Fhelp.jpg?alt=media&token=f548d15b-3d06-4d19-aa79-adad0c3d88e1");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;

}
.hero-info-sec2-left {
  font-weight: 600;
  font-size: 2.1rem;
  line-height: 50px;
  text-align: center;
  color: #484848;
  display: block;
}
@media only screen and (max-width: 991.98px) {
  .hero-info-sec2-left {
    text-align: left;
    margin-bottom: 5vh;
  }
  /* .hero-info-section-image {
    background-attachment: unset;
    background-repeat: unset;
  } */
}
@media only screen and (max-width: 575.98px) {
  .hero-info-section {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.hero-info-sec-right {
  font-weight: normal;
  font-size: 1.125rem;
  margin-left: 10%;
  color: #484848;
}
@media only screen and (max-width: 1199.98px) {
  .hero-info-sec-right {
    margin-left: auto;
  }
}


/* R E S O U R C E  S E C T I O N */
.subtitle{
  font-size: 13px;
  text-align: left !important;
}
.resources-wrapper {
  padding-bottom: 10%;
  margin-top: 2rem;
}
.resources {
  background: linear-gradient(
    180deg,
    rgba(255, 191, 25, 0) 0%,
    rgba(255, 191, 25, 0.25) 21.35%
  );
}
.resource-sec-title {
  font-weight: bold;
  font-size: 2.6rem;
  text-align: center;
  margin-top: 10vh;
  margin-bottom: 10vh;
  color: #484848;
}
/* very large deisplay */
@media only screen and (max-width: 1439.98px) {
  .resource-sec-title {
    margin-top: 20vh;
    margin-bottom: 10vh;
  }
}
/* desktop */
@media only screen and (max-width: 1119.98px) {
  .resource-sec-title {
    margin-top: 15vh;
    margin-bottom: 10vh;
  }
}
.home-resource {
  height: 100%;
  margin-top: 10vh;
  margin-bottom: 10vh;
}
@media only screen and (max-width: 1199.98px) {
  .home-resource {
    height: auto;

    margin-top: 2rem;
  }
}
.home-resource-content-container {
  width: 80%;
  margin-left: 2rem;
  margin-top: 3rem;
}

@media only screen and (max-width: 1199.98px) {
  .home-resource-content-container {
    width: 100%;
    margin-left: 0rem;
  }
}
.home-resource-image {
  width: 100%;
  background-size: cover;
  background-position: top;
}
.home-resource-heading {
  font-size: 1.5rem;
  color: #1c58b5;
  font-weight: bold;
}
.home-resource-text {
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.75);
}

.home-about-page-CTA-section {
  height: 90vh;
  background-image: linear-gradient(
      180deg,
      #ffffff 22.93%,
      rgba(255, 255, 255, 0.853442) 30%,
      rgba(255, 255, 255, 0.668388) 33.11%,
      rgba(255, 255, 255, 0.471515) 37.87%,
      rgba(255, 255, 255, 0.252895) 41.84%,
      rgba(255, 255, 255, 0) 46.42%
    ),
    url("https://firebasestorage.googleapis.com/v0/b/csfored-csforall.appspot.com/o/images%2Fgeneral%2Ffrom-above.jpg?alt=media&token=192787e3-b846-4f90-a4dd-04a7731c71a4");
  background-size: cover;
  background-position: top;
  position: relative;
}

.home-about-page-CTA-section-title {
  font-weight: 600;

  font-size: 1.9rem;
  text-align: center;

  color: #484848;
}
.home-about-page-CTA-section-content {
  margin-top: 10%;
}
.home-about-page-CTA-section-content a {
  margin-top: 5%;
}

/* Map css start */
.map-title{
  font-weight: bold;
  font-size: 2.2rem;
  text-align: center;
  margin-top: 10vh;
  margin-bottom: 7vh;
  color: #484848;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
   }

/* ================ MAP D3 CSS START =============== */
.iframe-container{
  width: 100%;
  height: auto;
 }

.map-btn.active{
  background-color: #1c58b5;
  color: white;
}
.map-btn:hover {
  text-decoration: none;
  /* transform: translateY(-3px); */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
}
.map-btn-container {
  width: 90%;
  margin-left: 10%;
  /* flex-direction: column; */
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.map-btns-container {
  width: 70%;
  margin: 0 auto;
  /* padding-left: 20%; */
} 
.map-btn{
  width: 37vh;
  height: 50px;
  color: #929292;
  font-weight: 700;
  background-color: #f7efe0;
  border: none;
  letter-spacing: 2px;
  outline: none;
  font-size: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.map-btn-left{
  border-radius: 2px 0px 0px 2px;
}

.map-btn-right{
  border-radius: 0px 2px 2px 0px;
}

.map-btn-states {
  width: 42vh;
  height: 55px;
  margin-right: 9%;
  padding: 0.5rem 2rem 0.5rem;
  background: #ed185b;
  border: none;
  border-radius: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all ease 0.3s;
}

.map-btn-states:hover {
  text-decoration: none;
  color: #ffffff !important;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
}
.map-btn-states:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) !important;
}

@media only screen and (max-width: 1000px){
  .map-btn{
    width: 35vh;
  }
  .map-btn-container {
    flex-direction: column;
    margin-left: 0%;
    width: 100%;

  }

  .map-btn-states {
    order: 1;
    margin-bottom: 12px;
 }
  .map-btns-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2;
  }
}

@media only screen and (max-width: 853px){
  .map-btn-states {
     margin-right: 0%;
  }
}
  @media only screen and (max-width: 570px){
    .map-btns-container{
    flex-direction: column;

    }
}

 /* ================ MAP IFRAME START =============== */




.iframe-center{
  width:90%;
  display:block;
  margin: 0 auto;
}
 .map-iframe{
  width:100%;
  height:98vh;
}

 @media only screen and (min-width: 1560px){
  .map-iframe{
    height: 720px;
 }
}
 @media only screen and (min-width: 1760px){
  .map-iframe{
  width: 1555px;
 }
}

@media only screen and (max-width: 853px){
  .map-iframe{
    height: 1400px !important;
  }
}

@media only screen and (max-width: 720px){
  .map-iframe{
    height: 1300px !important;
  }
}

@media only screen and (max-width: 550px){
  .map-iframe{
    height: 1100px !important;
  }
}

/* ================ MAP IFRAME END =============== */


.map-container{
  width: 90%;
  /* height: auto; */
  margin: 0 auto;
 }

 .spacer{
   height: 20px;
   width: 100%;
  }

  .spacer-lg{
    height: 20vh;
   width: 100%;
  }


  .control-panel {
    position: absolute;
    bottom: 7px;
    left: 0;
    max-width: 320px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    padding: 12px 24px;
    margin: 20px;
    font-size: 13px;
    line-height: 2;
    color: #6b6b76;
    /* text-transform: uppercase; */
    outline: none;
  }
  /* ================ MAP D3 CSS END =============== */


 /* uni-logo-section  start*/
 .uni-logo-section{
   padding-bottom: 4%;
 }
 .center{
  margin: 0 auto;
  text-align: left !important;
 }
 .logo-wall-container {
  width: 95%;
  margin: 0 auto;
  text-align: center;
 }

 .logo-wall img {
  height: auto;
  width: 22%;
  padding: 2%;
}

.florida{
  width: 15% !important;
}

.logo-wall-subtitle{
  width: 85%;
  margin: 0 auto;
}
/* uni-logo-section end */

