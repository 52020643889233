.button {  
    text-align: center;
    margin: 0 auto !important;
 
}

.center{
    text-align: center;
    margin: 0 auto !important;
}

.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
 }

 .row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
   padding-right: 0;
   padding-left: 0;
}

.pdf-container{
  position: relative;
  margin: 0 auto;
  width: 100%;
  overflow:hidden;
  padding-top: 50%; /* Aspect Ratio */
}
@media screen and (max-width:1056px){
  .pdf-container{
    padding-top: 60%; /* Aspect Ratio */
  }
}

@media screen and (max-width:853px){
  .pdf-container{
    padding-top: 70%; /* Aspect Ratio */
  }
}
@media screen and (max-width:553px){
  .pdf-container{
    padding-top: 150%; /* Aspect Ratio */
  }
}

.pdf-frame{
  width:100%;
  height:100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: none;
}
/* For all pages */
.all-page-container {
    height: 100%;
    max-height: 500px;
    overflow: auto;
  }

  hr {
    border-top: 2px dotted #3f51b5;
  }
  
  /* button {
    margin-right: 10px;
    background: #3f51b5;
    color: white;
    border: none;
    padding: 5px 10px;
    width: 100px;
    cursor: pointer;
    box-shadow: 2px 2px 2px 1px #ccc;
  } */
  
  /* For pdf */
  /* .react-pdf__Page {
    margin-top: 10px;
  } */
  .react-pdf__Page__textContent {
    /* border: 1px solid darkgrey; */
    box-shadow: 0px 0px 10px 5px #ccc;
    /* border-radius: 5px; */
  }
  
  /* .react-pdf__Page__annotations.annotationLayer {
    padding-bottom: 10px;
  } */
  
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }  
  .spacer{
      height: 35px;
      width: 100%;
  }
 