/* Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) 

/Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) 

 Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) 

 Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px)  */

.bold{
font-weight: bold;
}

.float-right{
  float: right;
}
.padding-left{
  padding-left: 3.5vh !important;
}

.nav-link {
  padding: 0;
}
/* individualy styled the dropdown in the nav */
.navbar-light .navbar-nav .nav-link {
  color: #1c58b5 !important;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #30bbf8 !important;
}
.NavLinks a {
  color: #1c58b5;
  text-decoration: none;
  transition: 0.2s;
}
.NavLinks:hover a {
  color: #30bbf8;
}

.logo-box {
  background: white;
  float: left;
  height: 64.75px;
}
nav {
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.logo-nav {
  height: 35px;
  width: auto;
  margin: 10px 8px 8px 8px;
  display: ineline-block;
}
@media only screen and (max-width: 991.98px) {
  .logo-nav {
    margin: 8px 8px 8px 8px;
  }
  .logo-box {
    height: 56px;
  }

  .dropdown-menu {
    outline: none !important;
    background-color: #f8f9fa;
  }
  .navbar-light .navbar-nav .nav-link:hover {
    color: #1c58b5 !important;
  }

  .navbar-light .navbar-nav .nav-link:visited {
    color: #1c58b5 !important;
  }

  .NavLinks:hover a {
    color: #1c58b5;
  }
}

.dropdown-link {
  font-weight: 600;
  display: inline-block;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #1c58b5 !important;
}

.dropdown-link:hover {
  background: #36b8f8 !important;
  color: white !important;
  text-decoration: none;
}
.dropdown-item:active {
  background: none;
}
.dropdown-item:hover {
  background: none;
}

