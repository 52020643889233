/* .iframe{
    width: 80%;
    margin: 0 auto;
} */


/* .mapboxgl-popup-content{
    width: 450px !important;
} */

/* 
.mapboxgl-popup{
    transform:none !important;
     top: 10%;
     bottom: 10%;
    right: 10px;
}
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-center .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip, 
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip{
    display:none !important;
} */

.overlays .mapboxgl-popup-content {
    padding: 22px 20px 15px;
}

.overlays .mapboxgl-popup-close-button {
    top: -6px;
    right: 10px;
}

.popupStyle{
    font-family: "Raleway", sans-serif;
    text-align: center;
    line-height: 2.5rem;
    font-size: 18px;
    font-style: normal;
}

.popupStyle h3 {
    font-size: 20px;
}

.popupStyle .city {
    margin-bottom: 0;
    font-weight: 400;
}

.popupStyle .btn {
    padding: 0.5rem 2rem 0.5rem;
    background: #ed185b;
    border: none;
    border-radius: 100px;
    text-align: center;
    font-weight: bold;
    font-size: .8rem;
    color: #ffffff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    transition: all ease 0.3s;
}

.popupStyle .btn:hover {
    text-decoration: none;
    color: #ffffff !important;
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgb(0 0 0 / 20%) !important;
}

.text-bold {
    font-weight: 600;
}

.popupStyle .programs-text {
    font-size: 1.1rem;
    margin-bottom: .5rem;
}

.icon{
    width: 2vh;
    height:auto;
    position: relative; 
    top: -1vh;
    left: 1vh;
}

.subtext{
    color: grey;
    font-size: 12px;
}

.hr-short{
    width: 70%;
    margin: 0 auto;
    position: relative; 
    top: -2vh;
    border: .8px solid;
}
.spacer{
    width: 3vh;
    height: auto;
}

.inline{
    display: inline-block;
}

.margin{
    margin-left: 4vh;
    margin-right: 4vh;
}

.title-width{
    width: 100%;
    margin: 0 auto;
    font-weight: 600;
    max-width: 80%;
}